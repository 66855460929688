var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('base-breadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-expansion-panels',{attrs:{"hover":"","focusable":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"title"},[_vm._v(" Avaliações a serem iniciadas ou já iniciadas "+_vm._s(_vm.toBeResolvedExams.loaded ? _vm.textExamsCount(_vm.startedNotStartedExamsState.pagination.total) : '')+" ")]),_c('v-expansion-panel-content',[_c('v-card',{staticClass:"mt-2"},[_c('v-card-title',[_vm._v(" Avaliações a resolver "+_vm._s(_vm.toBeResolvedExams.loaded ? _vm.textExamsCount(_vm.toBeResolvedExams.exams.length) : '')+" ")]),_c('v-card-text',[(_vm.toBeResolvedExams.loaded)?_c('v-row',{staticClass:"pa-2"},[(_vm.toBeResolvedExams.exams.length === 0)?_c('EmptyExams'):_vm._e(),_vm._l((_vm.toBeResolvedExams.exams),function(item){return _c('ToBeResolved',{key:item.id,attrs:{"item":item},on:{"select":function($event){return _vm.selectExam(item)},"setExam":function($event){return _vm.setExam(item)}}})})],2):_c('v-row',_vm._l((3),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"card-heading, list-item-three-line, list-item-two-line, actions"}},'v-skeleton-loader',{
                  boilerplate: true,
                  elevation: 2
                },false))],1)}),1)],1)],1),_c('v-card',{staticClass:"mt-8"},[_c('v-card-title',[_vm._v(" Avaliações não resolvidas "+_vm._s(_vm.unresolvedExams.loaded ? _vm.textExamsCount(_vm.unresolvedExams.exams.length) : '')+" ")]),_c('v-card-text',[(_vm.unresolvedExams.loaded)?_c('v-row',{staticClass:"pa-2"},[(_vm.unresolvedExams.exams.length === 0)?_c('EmptyExams'):_vm._e(),_vm._l((_vm.unresolvedExams.exams),function(item){return _c('Unresolved',{key:item.id,attrs:{"item":item},on:{"select":function($event){return _vm.selectExam(item)},"reviewPastApplication":function($event){return _vm.reviewPastApplication(item)}}})}),(_vm.startedNotStartedExamsState.pagination.total > 10)?_c('div',{staticClass:"text-center w-100"},[_c('v-pagination',{attrs:{"length":_vm.startedNotStartedExamsState.pagination.last_page,"circle":""},model:{value:(_vm.startedNotStartedExamsState.pagination.current_page),callback:function ($$v) {_vm.$set(_vm.startedNotStartedExamsState.pagination, "current_page", $$v)},expression:"startedNotStartedExamsState.pagination.current_page"}})],1):_vm._e()],2):_c('v-row',_vm._l((3),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"card-heading, list-item-three-line, list-item-two-line, actions"}},'v-skeleton-loader',{
                  boilerplate: true,
                  elevation: 2
                },false))],1)}),1)],1)],1)],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"title",on:{"click":_vm.loadPreviousExamsOnClick}},[_vm._v(" Avaliações anteriores "+_vm._s(_vm.previousExams.loaded ? _vm.textExamsCount(_vm.previousExams.pagination.total) : '')+" ")]),_c('v-expansion-panel-content',[(_vm.previousExams.loaded)?_c('v-row',{staticClass:"pa-2"},[(_vm.previousExams.exams.length === 0)?_c('EmptyExams'):_vm._e(),_vm._l((_vm.previousExams.exams),function(item){return _c('Previous',{key:item.id,attrs:{"item":item},on:{"select":function($event){return _vm.selectExam(item)},"reviewPastApplication":function($event){return _vm.reviewPastApplication(item)},"setExam":function($event){return _vm.setExam(item)}}})}),(_vm.previousExams.pagination.total > 10)?_c('div',{staticClass:"text-center w-100"},[_c('v-pagination',{attrs:{"length":_vm.previousExams.pagination.last_page,"circle":""},model:{value:(_vm.previousExams.pagination.current_page),callback:function ($$v) {_vm.$set(_vm.previousExams.pagination, "current_page", $$v)},expression:"previousExams.pagination.current_page"}})],1):_vm._e()],2):_c('v-row',_vm._l((3),function(i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-skeleton-loader',_vm._b({attrs:{"type":"card-heading, list-item-three-line, list-item-two-line, actions"}},'v-skeleton-loader',{
              boilerplate: true,
              elevation: 2
            },false))],1)}),1)],1)],1)],1),_c('dialog-details',{attrs:{"item":_vm.activeItem,"show":_vm.show},on:{"show":function($event){_vm.show = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }