<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <v-expansion-panels hover focusable multiple v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header class="title">
          Avaliações a serem iniciadas ou já iniciadas {{ toBeResolvedExams.loaded ? textExamsCount(startedNotStartedExamsState.pagination.total) : '' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card class="mt-2">
            <v-card-title>
              Avaliações a resolver {{ toBeResolvedExams.loaded ? textExamsCount(toBeResolvedExams.exams.length) : '' }}
            </v-card-title>
            <v-card-text>
              <v-row v-if="toBeResolvedExams.loaded" class="pa-2">
                <EmptyExams v-if="toBeResolvedExams.exams.length === 0" />
                <ToBeResolved v-for="item in toBeResolvedExams.exams" :key="item.id" :item="item" @select="selectExam(item)" @setExam="setExam(item)" />
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="4" v-for="i in 3" :key="i">
                  <v-skeleton-loader v-bind="{
                    boilerplate: true,
                    elevation: 2
                  }" type="card-heading, list-item-three-line, list-item-two-line, actions" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card class="mt-8">
            <v-card-title>
              Avaliações não resolvidas {{ unresolvedExams.loaded ? textExamsCount(unresolvedExams.exams.length) : '' }}
            </v-card-title>
            <v-card-text>
              <v-row v-if="unresolvedExams.loaded" class="pa-2">
                <EmptyExams v-if="unresolvedExams.exams.length === 0" />
                <Unresolved v-for="item in unresolvedExams.exams" :key="item.id" :item="item" @select="selectExam(item)" @reviewPastApplication="reviewPastApplication(item)" />
                <div class="text-center w-100" v-if="startedNotStartedExamsState.pagination.total > 10">
                  <v-pagination v-model="startedNotStartedExamsState.pagination.current_page"
                    :length=startedNotStartedExamsState.pagination.last_page circle></v-pagination>
                </div>
              </v-row>
              <v-row v-else>
                <v-col cols="12" md="4" v-for="i in 3" :key="i">
                  <v-skeleton-loader v-bind="{
                    boilerplate: true,
                    elevation: 2
                  }" type="card-heading, list-item-three-line, list-item-two-line, actions" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="title" @click="loadPreviousExamsOnClick">
          Avaliações anteriores {{ previousExams.loaded ? textExamsCount(previousExams.pagination.total) : '' }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row v-if="previousExams.loaded" class="pa-2">
            <EmptyExams v-if="previousExams.exams.length === 0" />
            <Previous v-for="item in previousExams.exams" :key="item.id" :item="item" @select="selectExam(item)"
              @reviewPastApplication="reviewPastApplication(item)" @setExam="setExam(item)" />
            <div class="text-center w-100" v-if="previousExams.pagination.total > 10">
              <v-pagination v-model="previousExams.pagination.current_page" :length=previousExams.pagination.last_page
                circle></v-pagination>
            </div>
          </v-row>
          <v-row v-else>
            <v-col cols="12" md="4" v-for="i in 3" :key="i">
              <v-skeleton-loader v-bind="{
                boilerplate: true,
                elevation: 2
              }" type="card-heading, list-item-three-line, list-item-two-line, actions" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <dialog-details :item="activeItem" :show="show" @show="show = $event" />
  </v-container>
</template>
<script>
import { format, parseISO, compareAsc } from 'date-fns'
import { mapActions, mapMutations, mapState } from 'vuex'
import ls from '@/libs/crypto'
import md5 from '@/libs/md5'
import store from '@/store'
import EmptyExams from '../../components/exams/EmptyExams.vue'

export default {
  /* Explicando o componente:
  Esse componente recebe informações de dois states, o startedNotStartedExamsState e o otherStatusExamsState
  O formatExams divide esses dois states em três objetos: toBeResolvedExams, previousExams e o unresolvedExams.
  o toBeResolvedExams são as provas que o usuário ainda pode resolver (estão com status STARTED e NOT_STARTED e ends_at > now)
  o unresolvedExams são as provas que o usuário não pode resolver (ends_at < now) mas ainda estão com status STARTED e NOT_STARTED
  o otherStatusExamsState são as provas que o professor já corrigiu (status: ENDED, NEED_BE_CORRECTED, CORRECTED, ...)
  Resumindo:
  startedNotStartedExamsState -> toBeResolvedExams, unresolvedExams
  otherStatusExamsState -> previousExams
  */
  data: () => ({
    format,
    parseISO,
    result: {},
    indexResult: 0,
    page: {
      title: 'Aplicações de Prova'
    },
    id: '',
    isLoading: true,
    panel: [0],
    toBeResolvedExams: {
      exams: [],
      pagination: {
        current_page: 1,
        last_page: 0,
        total: 0
      },
      loaded: false
    },
    previousExams: {
      exams: [],
      pagination: {
        current_page: 1,
        last_page: 0,
        total: 0
      },
      loaded: false
    },
    unresolvedExams: {
      exams: [],
      pagination: {
        current_page: 1,
        last_page: 0,
        total: 0
      },
      loaded: false
    },
    show: false,
    activeItem: null,
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Avaliações',
        disabled: false,
        to: '/resolution'
      }
    ],
    text: {
      searchLabel: 'Pesquisar Provas',
      emptyLabel: 'Nenhuma prova disponível'
    },
    disabledDescription: 'Prova já aplicada.',
    headers: [
      {
        text: 'Avaliação',
        align: 'start',
        value: 'title'
      },
      { text: 'Data Início', value: 'starts_at_formated', align: 'start' },
      { text: 'Data Término', value: 'ends_at_formated', align: 'start' },
      { text: 'Duração', value: 'duration', align: 'center' }
    ],
    sortBy: 'title'
  }),
  watch: {
    'previousExams.pagination.current_page': async function (newPage, oldPage) {
      this.previousExams.pagination.current_page = newPage
      await this.clearFetchFormatPreviousExams(newPage)
    },
    'startedNotStartedExamsState.pagination.current_page': async function (newPage, oldPage) {
      this.startedNotStartedExamsState.pagination.current_page = newPage
      await this.clearFetchFormatStartedNotStartedExams(newPage)
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    ...mapMutations('exam', ['SET_EXAM_SELECTED']),
    ...mapActions('exam', ['fetchstartedNotStartedExams', 'fetchPreviousExams']),
    md5,
    setExam (item) {
      if (!item.url) {
        this.$store.dispatch('alert', { color: 'red', msg: 'Atualizando dados: em breve estará disponível.' })
        return
      }
      ls.set(`exam/${item.id}`, item, { secret: item.id })
      this.SET_EXAM_SELECTED(item)
      this.$router.push({ path: item.url })
    },
    selectExam (item) {
      this.activeItem = item
      this.show = true
    },
    notInformed (value) {
      if (value === null || value === undefined || value === '') {
        return 'Dado em migração. Em breve disponível.'
      }
      return value
    },
    formatExams (exams) {
      if (exams.items.length === 0 && exams.loaded) {
        this.isLoading = false
        return exams
      }
      try {
        exams.items.forEach(value => {
          const obj = {
            ...value,
            title: this.notInformed(value.data.exam_application.name),
            description: value.data.exam_application.exam.description ? value.data.exam_application.exam.description : 'Sem descrição',
            id: this.notInformed(value.data.exam_application.id),
            duration: value.data.exam_application.duration ? `${value.data.exam_application.duration}m` : 'Dado em migração',
            total: parseInt(value.data.exam_application.duration, 10),
            author: this.notInformed(value.data.exam_application.author),
            access: {
              limit: this.notInformed(value.data.exam_application.access_limit),
              has_limit: this.notInformed(value.data.exam_application.has_limited_access),
              count: value.access_count
            },
            course: this.formatCourse(value.data.courses),
            starts_at_formated: value.data.exam_application.starts_at ? format(parseISO(value.data.exam_application.starts_at), "dd'/'MM'/'yyyy', às ' HH:mm'h'") : 'Dado em migração',
            ends_at_formated: value.data.exam_application.ends_at ? format(parseISO(value.data.exam_application.ends_at), "dd'/'MM'/'yyyy', às ' HH:mm'h'") : 'Dado em migração',
            url: value.data.exam_application.id ? `/resolution/view/${value.data.exam_application.id}` : '',
            student_exam: {
              status: value.data.status,
              status_formated: this.formatNameStatus(value.data.status),
              status_color: this.formatColorStatus(value.data.status),
              started_at_formated: value.data.started_at
                ? format(parseISO(value.data.started_at), "dd'/'MM'/'yyyy', às ' HH:mm'h'")
                : 'ainda não iniciado',
              ended_at_formated: value.data.ended_at ? format(parseISO(value.data.ended_at), "dd'/'MM'/'yyyy', às ' HH:mm'h'") : 'ainda não finalizado',
              grade: value.data.grade
                ? value.data.grade
                : 'ainda não calculada',
              time_spent: value.data.time_spent
                ? this.formatSeconds(value.data.time_spent)
                : 'ainda não finalizado'
            }
          }
          if (value) {
            if (['ENDED', 'NEED_BE_CORRECTED', 'CORRECTED', 'ANSWERS_FROM_BUBBLE_SHEET', 'PUBLISHED'].includes(value.data.status)) {
              this.previousExams.exams.push(obj)
              this.previousExams.pagination = exams.pagination
            }
            if (this.isPreviousDate(value.data.exam_application.ends_at)) {
              if (['STARTED', 'NOT_STARTED', 'CANCELED'].includes(value.data.status)) {
                this.unresolvedExams.exams.push(obj)
                this.unresolvedExams.pagination = exams.pagination
              }
            } else {
              if (['STARTED', 'NOT_STARTED'].includes(value.data.status)) {
                this.toBeResolvedExams.exams.push(obj)
                this.toBeResolvedExams.pagination = exams.pagination
              }
            }
          }
        })
      } catch (e) {
        console.error(e)
        this.isLoading = false
      }
      this.isLoading = false
    },
    isPreviousDate (date) {
      if (!date) {
        return false
      }
      if (compareAsc(new Date(Date.now()), parseISO(date)) === -1) {
        return false
      }
      return true
    },
    async reviewPastApplication (item) {
      this.$router.push({ path: `/result/exams/${item.data.exam_application.exam.id}/applications/${item.data.exam_application.id}/students/${item.metadata.id}` })
    },
    formatSeconds (sec) {
      const date = new Date(0)
      date.setSeconds(sec) // specify value for SECONDS here
      return date.toISOString().substr(11, 8)
    },
    formatNameStatus (value) {
      const status = {
        NOT_STARTED: 'Não iniciado',
        STARTED: 'Iniciado',
        ENDED: 'Finalizado',
        CANCELED: 'Cancelado',
        NEED_BE_CORRECTED: 'Precisa de correção',
        CORRECTED: 'Corrigido',
        PUBLISHED: 'Publicado',
        ANSWERS_FROM_BUBBLE_SHEET: 'Impresso'
      }
      return status[value] || ''
    },
    formatColorStatus (value) {
      const status = {
        NOT_STARTED: 'default white--text',
        STARTED: 'lime darken-3 white--text',
        ENDED: 'blue-grey darken-3 white--text',
        CANCELED: 'red',
        NEED_BE_CORRECTED: 'red darken-4 white--text',
        CORRECTED: 'primary',
        PUBLISHED: 'light-green accent-4',
        ANSWERS_FROM_BUBBLE_SHEET: 'blue-grey darken-3 white--text'
      }
      return status[value] || ''
    },
    formatCourse (course) {
      return course[0]
        ? {
          name: course[0].name,
          shift: course[0].shift,
          room: course[0].room,
          id: course[0].id,
          avatar: `https://gravatar.com/avatar/${md5(course[0].id)}?s=200&d=identicon&r=x`
        }
        : {
          name: '',
          shift: '',
          room: '',
          id: '',
          avatar: 'https://gravatar.com/avatar/?s=200&d=identicon&r=x'
        }
    },
    textExamsCount (examsLength) {
      if (examsLength === 1) {
        return '(1 avaliação)'
      }
      if (examsLength > 1) {
        return `(${examsLength} avaliações)`
      }
      return ''
    },
    async clearFetchFormatStartedNotStartedExams (page) {
      this.toBeResolvedExams.exams = []
      this.toBeResolvedExams.loaded = false
      this.unresolvedExams.exams = []
      this.unresolvedExams.loaded = false
      this.isLoading = true
      await this.fetchstartedNotStartedExams({ id: this.id, page })
      this.formatExams(this.startedNotStartedExamsState)
      this.toBeResolvedExams.loaded = true
      this.unresolvedExams.loaded = true
      this.isLoading = false
    },
    async clearFetchFormatPreviousExams (page) {
      this.previousExams.exams = []
      this.previousExams.loaded = false
      this.isLoading = true
      await this.fetchPreviousExams({ id: this.id, page })
      this.formatExams(this.otherStatusExamsState)
      this.previousExams.loaded = true
      this.isLoading = false
    },
    async loadPreviousExamsOnClick () {
      if (this.previousExams.loaded) {
        return
      }
      this.isLoading = true
      await this.fetchPreviousExams({ id: this.id })
      this.previousExams.loaded = true
      this.formatExams(this.otherStatusExamsState)
      this.isLoading = false
    }
  },
  computed: {
    ...mapState('organization', ['activeOrganization']),
    ...mapState('exam', ['startedNotStartedExamsState', 'otherStatusExamsState'])
  },
  async mounted () {
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    if (!this.id) {
      this.$router.push({ path: '/organization', replace: true })
      store.dispatch('alert', { color: 'red', msg: 'Você precisa selecionar uma instituição' })
    }
    if (!this.toBeResolvedExams.loaded) {
      await this.fetchstartedNotStartedExams({ id: this.id })
      this.toBeResolvedExams.loaded = true
      this.unresolvedExams.loaded = true
    }
    this.formatExams(this.startedNotStartedExamsState)
  },
  components: { EmptyExams }
}
</script>
<style>
.bullet-info {
  border-radius: 16px;
  padding: 0 12px;
  border: thin solid #0000001f;
  display: inline-flex;
  align-items: center;
  width: max-content;
  height: 32px;
}

ul.details li::before {
  content: "\2022";
  color: rgb(0, 110, 255);
  font-weight: bold;
  width: 16px;
  margin-left: -20px;
  font-size: 25px;
}

.application-title {
  min-height: 64px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
